import { Link } from 'react-router-dom';

const Post = ({type, title, link, summary, date}) => {
	return (
      <div class="py-8 flex flex-wrap md:flex-nowrap">
        <div class="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
          <span class="font-semibold title-font text-gray-700">{type}</span>
          <span class="mt-1 text-gray-500 text-sm">{date}</span>
        </div>
        <div class="md:flex-grow">
          <h2 class="text-2xl font-medium text-gray-900 title-font mb-2">{title}</h2>
          <p class="leading-relaxed">{summary}</p>
          <a href={link} class="text-indigo-500 inline-flex items-center mt-4">Read More
            <svg class="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path d="M5 12h14"></path>
              <path d="M12 5l7 7-7 7"></path>
            </svg>
          </a>
        </div>
        </div>
	)
}

const BlogList = () => {
	return (
<section class="text-gray-600 body-font overflow-hidden">
  <div class="container px-5 py-24 mx-auto">
    <div class="-my-8 divide-y-2 divide-gray-100">
       <Post type="POST" title="How To Learn Solana As A Beginner" link="/how-to-learn-solana-as-a-beginner" summary="A reading list to learn Solana & get you up to speed." date="Oct 26 2021"/>
       <Post type="POST" title="Is Solana Better Than Other Blockchains?" link="/is-solana-better-than-other-blockchains" summary="Our take on Solana's advantages relative to other blockchains." date="Sept 30 2021"/>
    </div>
  </div>
</section>
	)
}

export default BlogList;
