import logo from './logo.svg';
import './App.css';
import BlogList from './BlogList';
import BlogHack from './BlogHack';
import Hero from './Hero';

const Splash = () => {
  return (
		<div>
		<Hero/>
		<BlogList/>
		</div>
  );
}

export default Splash;

