import './App.css';
import { BrowserRouter as Router, Route } from "react-router-dom";
import React from 'react';
import Splash from './Splash';
import BlogHack from './BlogHack';
import "tailwindcss/tailwind.css";
import Hackathons from './Hackathons';
import WhatSolana from './WhatSolana';
import HowToLearnSolana from './HowToLearnSolana';
import NFTClub from './NFTClub';

function App() {
  return (
       	<Router>
    	<div>
            <Route path="/" exact component={Splash} />
            <Route path="/how-to-learn-solana-as-a-beginner" component={HowToLearnSolana} />
            <Route path="/is-solana-better-than-other-blockchains" component={WhatSolana} />
            <Route path="/the-nft-club" component={NFTClub} />
    	</div>
	</Router>
  )
}
 
export default App;


