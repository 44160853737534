import './App.css';
import './index.css';
import BlogForm from './BlogForm';
import { Fragment } from 'react';
import styled from 'styled-components';

const title="Why Solana Is Better"
const image="solana_illo.png"
const txt="solana explainer"
const caption=""

const asty={
	textDecoration: 'none',
	color: 'blue',
};

const hack_text = <Fragment>
<p>You may have heard about Solana, which has been attracting some of the energy, and enthusiasm, of the early days of Ethereum.</p>
	<p>Crypto is a crowded space - what does Solana bring?</p>
<p>It's a fast blockchain, written in a performant language, solving one of the biggest problems plaguing the top blockchains today: high transaction fees.</p>
<p>That's important, because solving the transaction fee problem makes other kinds of applications possible, like high speed exchanges and microtransactions.</p>
<p>To dig into that, it helps to understand the state of crypto today. </p>
	<h2>An Origin Story</h2>
<p>The first blockchain was Bitcoin. Everyone knows Bitcoin, so I'll skip the introduction.</p>
	<p>The second great blockchain, coming roughly six years later, was Ethereum. 
	</p>
	<p>Ethereum was created because there were things people wanted to do on a blockchain, that couldn't be done on the Bitcoin network.</p>
<p>The problem was that it was hard to program the blockchain. The most famous founder of Etheruem, Vitalik Buterin, concluded that something new was needed.</p>

	<p>Ethereum was designed from the ground up with new possibilities in mind, especially smart contracts, where you could combine a cryptocurrency with logic that was also on the blockchain.</p>

<p>So Ethereum expanded the universe of possibilities.</p>
<p>Now let's look at how the crypto landscape looked, before the arrival of Solana.</p>
<p>Starting with the blockchains already listed, you have blockchain transactions with Bitcoin, and you have on-chain logic with Ethereum. </p>
	<p>What you don't have is decentralized transactions that are fast and cheap. You can't have an application that frequently uses transactions to make changes to a person's account, for example, if each transaction costs upwards of $20.</p>
<p> This matters. If the fees for each transaction are high and if the minimum time to confirm transactions is several minutes, that affects what you can do.</p>
	<h2>An Analogy</h2>
<p>Imagine you run a coffeeshop. </p>
	<figure>
	<img src="coffee_workers.png" alt="coffeeshop workers"/>
	<a style={{textDecoration: 'none',color: 'gray'}} href="https://www.pexels.com/photo/woman-in-white-long-sleeve-shirt-sitting-on-chair-4790059/"><figurecaption>Photo by cottonbro from Pexels</figurecaption></a>
	</figure>
	<p>It's a normal coffeeshop except for this: every time a customer makes a transation, there is a 10 dollar flat fee - the network's transaction fee, in essence.</p>
<p>There's nothing stopping you from, theoretically, doing anything with your coffeeshop that you couldn't do without that surcharge. In reality, though, a fee like that changes your situation dramatically. </p>
	<p>
	Your coffeeshop is pretty much forced to go high end, and stock only a select range of coffees, in order for your business to work. No one is going to buy a $1 dollar pastry for $11.</p>
<p>Now imagine another rule: you can only ring up a customer once every 3 minutes.</p>
<p>You can see how these 'minor inconveniences' profoundly affect your business.</p>
<p>So lowering fees and increasing transaction throughput, by analogy, isn't really an unimportant distinction. </p>
	<p>From the indepenent developer's perspective - the store owner - it's crucially important. It's more than a detail; changing that changes the game.</p>
<h2>      Overview      </h2>
<p>Solana's advantages come from using existing blockchains solutions as a baseline to do better.</p>
<p>This was true from the very beginning. See this quote, from an early team member, <a style={asty}href="https://twitter.com/hsinjuchuang">Hsin-Ju Chuang</a>:</p>
<p style={{fontStyle:'italic',}}>	The problem they’re solving is a real one, as I’ve encountered many DApps and Layer 2 protocols built on Ethereum that can 100% never go to mainnet due to scalability issues & high gas fees.</p>
<p>As a user, you can think of it as being based around these strengths:	it is fast, efficient, and inexpensive.</p>
	<p>It was created by a team of engineers who though about questions like concurrency and throughput on an industrial scale. Several were ex-Qualcomm, a company which creates semiconders, software and services based around wireless technology, and is used to solving hard problems. </p>
<p>      One choice they made is in the language of the protocol itself.</p>
	<p>It's written in Rust, as are all the programs for it. It doesn't have it's own dedicated language, like Ethereum does with Solidity. Instead, it's written in a language with an emphasis on type safety and strictness.</p>
<p>      Another choice is how the blockchain itself is organized. </p>
<p>      State is stored in accounts. All the data in programs, or smart contracts, is stored in an account - actually, multiple accounts. By getting the programmer to manage that, they essentially move some of the responsibility off of the blockchain, reducing the comptuational cost. </p>
	<p>This is another one of those choices that, when compared to blockchains like Ethereum, is meant to make things faster.</p>
     <p>To <a href="https://sinoglobalcap.medium.com/why-we-are-bullish-on-solana-c2be784cfdf6" style={asty}>quote</a> <a style={asty} href="https://twitter.com/mattysino">@mattysino</a> of <a style={asty} href="https://www.sinoglobalcapital.com/">Sino Global Capital</a>:</p>
<p style={{fontStyle: 'italic',}}> A bet on Solana is a bet that one day we will need fully decentralized systems that can support thousands of high-value complex transactions per second and that the chain that can support that scalability and finality today will accrue significant value.</p>
     <h2>How It Works</h2> 
<p>One way to frame the problem is to start with a problem every blockchain has to tackle: consensus.</p>
	<p>This is sometimes illustrated by the <a style={asty} href="https://coincentral.com/byzantine-generals-problem/#:~:text=The%20Byzantine%20Generals%20Problem%20is,information%20or%20are%20otherwise%20unreliable.">Byzantine General's problem</a>.</p>
	<p>Imagine you have seven generals trying to agree on an attack time, but never being sure that enough of the generals have agreed upon a time to start. Some of the submitted times may come from imposters who are actually enemy soldiers, making the problem harder.</p>
	<img src="spiderman_many.png" alt="many spiderman"/>
<p>In a risky environment, trust is hard. If you have a group of people, or nodes, and you need to get them to agree on all the transactions that have happened - how do you do it?</p>
	<p>Solving this problem ends up consuming a lot of computing resources. It's why so many blockchains take so long to do it - which increases the transaction cost.</p>
	<p>Normally, you could say that most blockchains focus on the agreement part, and treat the clock as a secondary element each node tracks individually.</p>
	<p>Solana puts the clock part first and actually making that a part of the solution. This is the Proof of History part. </p>
	<p>The validators of truth on the newtork don't have to manufacture consensus through the sequential production of blocks. They encode the passage of time and send that out to the network.</p>
	<p>The network then assembles and re-assembles this to come up with a data structure reflecting their input. Because messages don't have to arrive at validators in a timely manner, the network can move faster.</p>
	<p>This creates a significant speed improvement, which is at the core of Solana's innovation. Anatoly Yakovenko, CEO of Solana Labs, has been <a href="https://newsletterest.com/message/68550/Solana-Summer" style={asty}>quoted</a> as saying that Solana involves “the implementation of the arrow of time in math.”</p>
	<p>Other pieces of the protocol rely heavily on this Proof of History innovation, such as Tower PBFT (PBFT stands for Practical Byzantine Fault Tolerance - think back to the Byzantine General's problem mentioned above).</p>
	<p>This spirit of innovation is everywhere. Pieces like Solana's block propagation protocol, Turbine, depend heavily on techniques for sending data out that were pioneered by cutting edge projects like BitTorrent.</p>
	<p>Other notable components include Gulf Stream, a transaction forwarding protocol that keeps unconfirmed transactions low, and Sealevel, which can do parallel smart contract execution (unlike Ethereum, which does one at a time).</p>
	<p>For a longer explanation, see Solana founder Anatoly Yakovenko's <a href="https://medium.com/solana-labs/proof-of-history-a-clock-for-blockchain-cf47a61a9274" style={asty} >article series</a> on Medium.</p>
<p>Generally, there is an emphasis in Solana on trying to take every step to speed the network up, by doing computations in parallel and thinking about how time can be used more productively within a blockchain environment.</p>
	<p>For more information, please see the <a href="https://docs.solana.com/" style={asty}>Solana docs</a> (especially the <a style={asty} href="https://docs.solana.com/developing/programming-model/overview">developer docs</a>) or this excellent <a style={asty} href="https://github.com/ilmoi/solana-onboarding">document portal</a> for getting started.</p>
	<p>And as always - happy hacking!</p>
	 
</Fragment>

const body = <Fragment>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="p-2 mt-2 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
	{title}
            </span>
          </h1>
          <figure>
            <img
              className="w-full rounded-lg pt-6 pb-2"
              src={image}
              alt={txt}
            />
            <figcaption>{caption}</figcaption>
          </figure>
        </div>
        <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
	{hack_text}
        </div>
      </div>
</Fragment>

const WhatSolana = () => {
	return (
		<BlogForm content={body} />
	)
}

export default WhatSolana;
