import './App.css';
import './index.css';
import BlogForm from './BlogForm';
import { Fragment } from 'react';

const title="Join the Club"
const image="digital_apes.png"
const txt="nfts for members"
const caption=""

const hack_text = <Fragment>

      <p>They say everybody gets 15 minutes of fame. If you're on Twitter, reading about NFT's, those 15 minutes have started to stretch over several weeks.</p>
<p>      Most people know the name of NFT, now. But do they know what they enabled?</p>
<p>      NFT's like the Bored Ape Yacht Club have raised so much money, so fast, that they've essentially created a new funding model.</p>
<p>      In the case of the Bored Ape Yacht Club, their haul, so far, is 26.2 million dollars.</p>
<p>      But don't focus on the monetary amount. Think about what it signifies. 
</p>
<p> It means that many crypto holders are willing to pay to be part of a community. </p>
<p> If you can make that community for them, or make a community they want to join, they'll support you. With serious money.</p>
<p>      Sometimes, it's groups, or companies, doing this. In the case of the Bored Apes, its Yuga Labs.</p>
<p>      In some cases, it's individuals. The most famous one today is probably Gary Vee.</p>
<p>      He's an influencer, a famous marketer, who's all but unavoidable on YouTube. He's now created his own NFT collection, to gatekeep access to his community.</p>
<p>      And his people love it. A quick check of his website, today, shows his typical NFT's selling from 51,000 to just over 200,000.</p>
      <p>The future lies this way.</p> 
<h2>      A Prediction      </h2>
	<img alt="gary vee nft website" src="vee_banner.png"/>
<p>      We're going to see a lot more NFT's following a model like this.</p>
<p>      First, you buy an NFT. That can happen anywhere: off OpenSea, from someone you know, or from one of many other marketplaces.</p>
<p>      Then, you use those NFT's to access resources, or places, that have value to you.</p>
<p>      It could be your favorite influencer's online website, or a behind the scenes venue, virtual or physical. </p>
<p>      Ownership of the NFT is proof that you belong. And like all such blockchain things, it's cryptographically secure. When you're in, you're in forever, for as long as you own the underlying asset.</p>
<p>      Not only do you get to support your favorite creator, but you can also join a community of other people who do too, who share your interests. By buying your NFT, you signify that you belong.</p>
<p>    We believe this is the way. We're excited by the opportunities that NFT's bring to creators.</p>
	<p>We support any movement that empowers them, together with everyone reading this, and everyone who wants to make the world a better place.</p>
      
</Fragment>

const body = <Fragment>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="p-2 mt-2 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
	{title}
            </span>
          </h1>
          <figure>
            <img
              className="w-full rounded-lg pt-6 pb-2"
              src={image}
              alt={txt}
            />
            <figcaption>{caption}</figcaption>
          </figure>
        </div>
        <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
	{hack_text}
        </div>
      </div>
</Fragment>

const NFTClub = () => {
	return (
		<BlogForm content={body} />
	)
}

export default NFTClub;
