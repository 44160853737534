import './App.css';
import './index.css';
import BlogForm from './BlogForm';
import { Fragment } from 'react';
import styled from 'styled-components';

const title="How to Learn Solana As A Beginner"
const image="spongebob.gif"
const txt="solana explainer"
const caption=""

const asty={
	textDecoration: 'none',
	color: 'blue',
};

const hack_text = <Fragment>
<p>So you wan to learn Solana.</p>
<p>Next question: How?</p>
<p>One good place to start, that makes this less of a solo effort, is with the community.</p>
<p>If you only follow one person, it should be <a style={asty} href="https://twitter.com/therealchaseeb">Chase Barker</a>, Solana's developer relations lead, on Twitter. He'll often link to good tutorials and helpful beginner materials. </p>
<p>Next up, you'll probably want to read through developer resources.</p>
<p>There are good <a style={asty} href="https://github.com/CristinaSolana/solana-developer-resources">curated lists</a> on GitHub. There's more sites, like <a style={asty} href="https://soldev.app">soldev.app</a>, cropping up for general education. And there's always the <a href="https://docs.solana.com/" style={asty}>official docs</a>.</p>
	<p>Here's my version of a beginner's reading list.</p>
<p> Pineapple dev link</p>
<p><a style={asty} href='https://solana.blog/building-on-solana-the-experience-of-a-hackathon-participant/'>solana.blog/building-on-solana-the-experience-of-a-hackathon-participant</a></p>
<p>PaulX's site</p>
<p><a style={asty} href='https://paulx.dev/blog/2021/01/14/programming-on-solana-an-introduction/'>paulx.dev/blog/2021/01/14/programming-on-solana-an-introduction</a></p>
<p>Figment</p>
<p><a style={asty} href='https://learn.figment.io/tutorials/fund-solana-account'>learn.figment.io/tutorials/fund-solana-account</a></p>
<p>Figment Notion</p>
<p><a style={asty} href='https://www.notion.so/Solana-f2a44dd809714736b0bdbe3fe6cc0ae9'>notion.so/Solana-f2a44dd...</a></p>
<p>Nader Dabits tutorial</p>
<p><a style={asty} href='https://dev.to/dabit3/the-complete-guide-to-full-stack-solana-development-with-react-anchor-rust-and-phantom-3291'>dev.to/dabit3/the-complete-guide-to-full-stack-solana-development</a></p>
<p>Programs and accounts</p>
<p><a style={asty} href='https://2501babe.github.io/posts/solana101.html'>2501babe.github.io/posts/solana101.html</a></p>
<p>YT: Choi Building w Solana</p>
<p><a style={asty} href='https://www.youtube.com/watch?v=gA7hFdq2h9Q&list=PL41Cw3fN3CfdbmhgxADwyDyIoDrxc22v2'>youtube.com/watch?v=gA7hFdq...</a></p>
<p>Figment: Create A Mail App</p>
<p><a style={asty} href='https://learn.figment.io/tutorials/create-a-fullstack-mail-dapp-on-solana'>learn.figment.io/tutorials/create-a-fullstack-mail-dapp-on-solana</a></p>
      <p>Go through all these, and you'll be well prepared to develop on Solana.</p>
	 
</Fragment>

const body = <Fragment>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="p-2 mt-2 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
	{title}
            </span>
          </h1>
          <figure>
            <img
              className="w-full rounded-lg pt-6 pb-2"
              src={image}
              alt={txt}
            />
            <figcaption>{caption}</figcaption>
          </figure>
        </div>
        <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
	{hack_text}
        </div>
      </div>
</Fragment>

const HowToLearnSolana = () => {
	return (
		<BlogForm content={body} />
	)
}

export default HowToLearnSolana;
